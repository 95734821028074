/**
 * Set the value for --fixed-viewport-height based on the current window height. This value is updated when the window
 * height changes, but only in increments of at least 110px.
 */
(function() {
  // Initialize a value for --fixed-viewport-height
  let viewportHeight;
  const documentStyle = document.documentElement.style;
  updateFixedViewportHeight();

  // Update the value for --fixed-viewport-height any time the window size changes by more than 110px
  window.addEventListener('resize', () => {
    if (Math.abs(window.innerHeight - viewportHeight) > 110) {
      updateFixedViewportHeight();
    }
  });

  /**
   * Update the fixed viewport height variable to the current viewport height.
   */
  function updateFixedViewportHeight() {
    viewportHeight = window.innerHeight;
    documentStyle.setProperty('--fixed-viewport-height', viewportHeight + 'px');
  }
})();
