(function() {
  // Find the required page parts
  const scrollSpyNav = document.getElementById('closer-look-nav');
  const scrollSpy = document.querySelector('[data-spy="scroll"][data-target="#closer-look-nav"]');
  const svgWireFrame = document.querySelector('#closer-look-sidebar svg');

  // Do nothing if and of the required elements could not be found
  if (!scrollSpyNav || !scrollSpy || !svgWireFrame) return;

  // Set the scrollSpy 'offset' parameter to be 33% of the page height
  $(scrollSpy).scrollspy({offset: window.innerHeight * 0.33});

  // Handle the scroll spy 'activate' event, that is fired when a nav element section becomes active
  // NOTE: The event fires from the 'window' for some reason: https://github.com/twbs/bootstrap/issues/20086
  const scrollSpyNavList = scrollSpyNav.querySelector('ul');
  $(window).on('activate.bs.scrollspy', onScrollSpyActivated);

  // Initialize by simulating an activation event now
  onScrollSpyActivated();

  // Handle click events on the svg components
  const svgComponents = svgWireFrame.querySelectorAll('[data-ffe-component]');
  svgComponents.forEach((component) => component.addEventListener('click', () => {
    const componentId = component.getAttribute('data-ffe-component');
    const navLink = scrollSpyNav.querySelector(`[href='#ffe-component-${componentId}']`);
    navLink.click();
  }));

  /**
   * Center the current nav element and highlight the current component in the side-bar svg.
   */
  function onScrollSpyActivated() {
    const activeNavLink = scrollSpyNavList.querySelector('.nav-link.active');
    const activeNavItem = activeNavLink.parentElement;

    // Center the current nav element, if it has a horizontal scroll
    if (scrollSpyNavList.scrollWidth > scrollSpyNavList.clientWidth) {
      const scrollLeft =
        activeNavItem.offsetLeft -
        scrollSpyNavList.clientWidth * 0.5 +
        activeNavItem.clientWidth * 0.5;
      scrollSpyNavList.scrollTo({left: scrollLeft, behavior: 'smooth'});
    }

    // Find the old and current target SVG groups
    const targetComponent = activeNavLink.getAttribute('href').substring('#ffe-component-'.length);
    const previousTargetSvgGroups = svgWireFrame.querySelectorAll('[data-ffe-component].active');
    const targetSvgGroup = svgWireFrame.querySelector(`[data-ffe-component=${targetComponent}]`);

    // Show the target group, and hide the rest
    previousTargetSvgGroups.forEach((g) => g.classList.remove('active'));
    if (targetSvgGroup) targetSvgGroup.classList.add('active');
  }
})();
