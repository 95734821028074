/**
 * Track and submit analytics events
 */
(function() {
  // Attach analytics handlers when the window load event fires, after all async scripts have loaded
  // The Google Analytics script is loaded with the 'async' attribute so we need to use this event
  $(window).on('load', attachAnalyticsHandlers);

  /**
   * Attach analytics handlers to various elements on page load.
   */
  function attachAnalyticsHandlers() {
    try {
      // Do nothing if Google Analytics is not loaded (i.e. ad blocker, etc)
      if (typeof gtag !== 'function') return;

      // Track all link clicks (only submit event on first click)
      // TODO: Prevent navigating until the event has been submitted
      $('a[href]').click(function() {
        const action = this.hasAttribute('download') ? 'Download ' : 'Navigate to ';
        gtag('event', action + this.href, {'event_category': 'Click'});
      });

      // Track button clicks (only submit event on first click)
      $('button[data-track]').click(function() {
        const buttonName = $(this).data('track');
        gtag('event', 'Click button ' + buttonName, {'event_category': 'Click'});
      });
    } catch (error) {
      console.error('Failed to attach analytics event handlers', error);
    }
  }
})();
