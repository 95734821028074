(function() {
  // Find the banner element. Do nothing if this page does not have the banner.
  const banner = document.getElementById('closer-look-banner');
  if (!banner) return;

  // Find other relevant elements in the banner
  const navList = banner.querySelector('.nav');
  const navLinks = Array.from(navList.querySelectorAll('.nav-link'));
  const svgWireFrame = banner.querySelector('svg');
  const svgGroups = Array.from(svgWireFrame.querySelectorAll('[data-ffe-component]'));

  // Handle hover events for the banner nav links
  navLinks.forEach((navLink) => navLink.addEventListener('mouseenter', () => activateNavLink(navLink)));

  // Handle hover events for the SVG components
  svgGroups.forEach((svgGroup) => svgGroup.addEventListener('mouseenter', () => activateSvgGroup(svgGroup)));

  // Handle click events for the SVG components by simulating a click on the associated nav link
  svgGroups.forEach((svgGroup) => svgGroup.addEventListener('click', () => {
    const componentId = svgGroup.getAttribute('data-ffe-component');
    const navLink = navList.querySelector(`[data-select-ffe-component='${componentId}']`);
    navLink.click();
  }));

  // Start up an interval to automatically cycle through the banner nav items
  setInterval(activateNextNavLink, 3000);

  /**
   * Activates the next nav link.
   */
  function activateNextNavLink() {
    // Do nothing if the user is hovering over a link, or an SVG component
    if (navLinks.filter((l) => l.matches(':hover')).length) return;
    if (svgGroups.filter((l) => l.matches(':hover')).length) return;

    // Find the index of the current active nav item
    const activeIndex = navLinks.map((x) => x.classList.contains('active')).indexOf(true);

    // Increment the index to get the next item that we should make active
    const nextIndex = (activeIndex + 1) % navLinks.length;

    // Perform activation behaviour
    activateNavLink(navLinks[nextIndex]);
  }

  /**
   * Activate the component associated withe the specified nav link
   *
   * @param {Element} navLink The link in the nav associated with the component to activate
   */
  function activateNavLink(navLink) {
    // Find the associated SVG element
    const componentId = navLink.getAttribute('data-select-ffe-component');
    svgComponent = svgWireFrame.querySelector(`[data-ffe-component=${componentId}]`);

    // Activate the component
    activateComponent(navLink, svgComponent);
  }

  /**
   * Activate the component associated withe the specified SVG group
   *
   * @param {Element} svgGroup The SVG group in the SVG associated with the component to activate
   */
  function activateSvgGroup(svgGroup) {
    // Find the associated nav link
    const componentId = svgGroup.getAttribute('data-ffe-component');
    const navLink = navList.querySelector(`[data-select-ffe-component='${componentId}']`);

    // Activate the component
    activateComponent(navLink, svgGroup);
  }

  /**
   * Activate the component associated with the specified nav link and SVG group
   *
   * @param {Element} navLink The link in the nav associated with the component to activate
   * @param {Element} svgGroup The SVG group in the SVG associated with the component to activate
   */
  function activateComponent(navLink, svgGroup) {
    // Mark this link as active and all others as inactive
    navLinks.forEach((x) => x.classList.remove('active'));
    navLink.classList.add('active');

    // Scroll the active item into view
    // NOTE: This is only for small screens when the nav list is horizontal
    if (navList.scrollLeft > navLink.offsetLeft - 100) {
      navList.scrollTo({
        left: navLink.offsetLeft - 100,
        behavior: 'smooth'});
    } else if (navList.scrollLeft + navList.clientWidth < navLink.offsetLeft + navLink.clientWidth + 40) {
      navList.scrollTo({
        left: navLink.offsetLeft + navLink.clientWidth - navList.clientWidth + 40,
        behavior: 'smooth'});
    }

    // Deactivate any old SVG groups, then activate the current group
    svgWireFrame
        .querySelectorAll('[data-ffe-component].active')
        .forEach((g) => g.classList.remove('active'));
    if (svgGroup) svgGroup.classList.add('active');
  }
})();
