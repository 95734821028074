// Polyfill for 'NodeList.prototype.forEach', since it is not included in corejs@2
// See: https://github.com/zloirock/core-js/issues/329#issuecomment-346178508
// Taken from: https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
// TODO: Update to corejs@3 and remove this
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Polyfill for 'Element.prototype.closest'
// Taken from: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
