/* global fbq, gtag */

(function() {
  $('#newsletter-subscribe-modal form').on('submit', function(e) {
    // Prevent default handler
    e.preventDefault();

    // Set form readonly flags on form elements
    const formElem = this;
    for (const inputElem of formElem.elements) {
      inputElem.readOnly = true;
    }

    // Submit form, then show success or failure modal
    submitForm($(formElem)).then(submitSuccess, submitFailure);
  });

  /**
   * Submit handler for the form.
   *
   * @param {JQuery<HTMLFormElement>} $form The form to submit (JQuery object)
   * @return {JQuery.jqXHR} A promise that is resolved/rejected when the POST request completes.
   */
  function submitForm($form) {
    // Get the form data as an array and submit it via AJAX
    const data = $form.serializeArray();
    return $.post($form.attr('action'), data);
  }

  /**
   * Callback when the form is submitted successfully.
   */
  function submitSuccess() {
    // Hide the form and show a success modal
    $('#newsletter-subscribe-modal').modal('hide');
    $('#newsletter-subscribe-success-modal').modal();

    // Record analytics events
    if (typeof fbq === 'function') fbq('track', 'Lead', {content_name: 'Newsletter Subscription'});
    if (typeof gtag === 'function') gtag('event', 'generate_lead');
  }

  /**
   * Callback when the form submission fails.
   */
  function submitFailure() {
    //  Hide the form and show a failure modal
    $('#newsletter-subscribe-modal').modal('hide');
    $('#newsletter-subscribe-failure-modal').modal();
  }
})();
