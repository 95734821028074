(function() {
  // Prevent <iframe> video from autoplaying
  $('.media-modal iframe').on('load', function() {
    const $video = $(this.contentDocument).find('video').removeAttr('autoplay');
    if ($video.length) $video[0].pause();
  });

  // When a media modal is hidden, stop the video from playing
  $('.media-modal').on('hidden.bs.modal', function(event) {
    const $videoContainer = $(event.currentTarget).find('.embed-responsive');
    const $videoFrame = $videoContainer.find('iframe');

    if ($videoFrame.hasClass('_iub_cs_activate-activated')) {
      // If the cookie policy was accepted, this is a Vimeo video
      // Add a new clone of the video element and remove the existing video element to stop it playing
      $videoContainer.clone().insertAfter($videoContainer);
      $videoContainer.remove();
    } else {
      // Otherwise, this is an HTML <video> element that we can stop directly
      const $video = $($videoFrame[0].contentDocument).find('video');
      $video[0].pause();
    }
  });
})();
