/**
 * Initialize sticky-top components
 */
(function() {
  const $stickyElements = $('.sticky-top');

  // Create a placeholder element
  $stickyElements.after($('<div class="sticky-top-placeholder"></div>'));

  $(window).on('scroll resize', debounce(updateStuckTopState, 10));
  updateStuckTopState();

  /**
   * Update the .stuck-top class for each .sticky-top element.
   */
  function updateStuckTopState() {
    const isScrolled = window.scrollY > 50;
    $stickyElements.toggleClass('stuck-top', isScrolled);
  }

  /**
   * Returns a function, that, as long as it continues to be invoked, will not be triggered. The function will be
   * called on both the leading and trailing edges of the timeout.
   *
   * Based on: https://davidwalsh.name/javascript-debounce-function
   *
   * @param {*} func The function to execute when the timeout elapses.
   * @param {*} wait The timeout duration.
   * @return {Function} The generated function.
   */
  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      // Define a function to call after the timeout
      const context = this;
      const later = function() {
        timeout = null;
        func.apply(context, args);
      };

      // Call now if the timeout has not been set yet
      if (timeout === null) func.apply(context, args);

      // Reset the timeout to wait another 'wait' milliseconds
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
})();
