(function() {
  const $header = $('#main-nav');

  $('[data-scroll-to]').on('click', function(e) {
    // Cancel the native event
    e.preventDefault();

    // Get the target element; do nothing if it does not exist
    const targetSelector = this.dataset.scrollTo;
    const targetElement = document.querySelector(targetSelector);
    if (!targetElement) return;

    // Scroll to the target element
    scrollToElement(targetElement);

    // After a short delay, recalculate the scroll position and scroll again in case the header size changed
    setTimeout(() => scrollToElement(targetElement), 400);
  });

  /**
   * Smooth scroll to the specified element
   *
   * @param {Element} element The element to scroll to
   */
  function scrollToElement(element) {
    // Calculate the target scroll position, accounting for the header height
    const targetScrollPosition =
      element.getBoundingClientRect().top +
      document.documentElement.scrollTop -
      $header.outerHeight();

    // Scroll the window to the target position
    window.scrollTo({top: targetScrollPosition, behavior: 'smooth'});
  }
})();
