(function() {
  // Overlay the notification banner when the page has a full-height page banner that we don't want to shift down
  // (except on the about page, because it looks better shifted down)
  const overlayBanners = !!document.querySelector('.page-banner') &&
  !window.location.pathname.startsWith('/about');

  const banners = document.getElementsByClassName('notification-banner');
  Array.from(banners).forEach(initializeBanner);

  /**
   * Initialize the specified banner
   *
   * @param {Element} banner The banner to initialize.
   */
  function initializeBanner(banner) {
    // Cancel out the banner height if necessary (it will overlay the element below it)
    if (overlayBanners) banner.style.marginBottom = `-${banner.clientHeight}px`;

    // Set up close click handler
    const closeButton = banner.querySelector('.close');
    closeButton.addEventListener('click', function() {
      $(banner).slideUp();
    });
  }
})();
