/**
 * Initialize all scroll carousel controls
 */
(function() {
  $('[data-scroll-slide]').each((index, element) => {
    $(element).on('click', function() {
      // Get relevant elements and options
      const direction = $(this).data('scroll-slide');
      const increment = $(this).data('scroll-increment') || 1;
      const carouselSelector = $(this).data('target');
      const $carousel = $(carouselSelector).find('.row.scroll-carousel-inner');
      const $carouselItems = $carousel.find('.scroll-carousel-item');

      // Calculate the target scroll position for the carousel (assume all elements are the same width)
      const itemWidth = $carouselItems.first().outerWidth() * increment;
      const currentScroll = $carousel.scrollLeft();
      const targetScroll = currentScroll + itemWidth * (direction === 'next' ? 1 : -1);

      $carousel[0].scroll({
        left: targetScroll,
        behavior: 'smooth',
      });
    });
  });
})();
