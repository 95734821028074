(function() {
  // Add submit handler to contact form
  // - Cancels the default submit behaviour
  // - Set the form to readonly while handling
  // - Call the custom submitForm() handler below
  $('.contact-form-wrapper form').submit(function(e) {
    const formElem = this;
    try {
      // Prevent default handler
      e.preventDefault();

      // Set form readonly flags
      setReadOnly(formElem, true);

      // Submit form, show success or failure modal, reset readonly flags on completion
      submitForm($(formElem))
          .then(submitSuccess, submitFailure)
          .then(() => setReadOnly(formElem, false));

      // Return false to prevent default handler
      return false;
    } catch (err) {
      // Reset readonly flags on error before re-throwing error
      setReadOnly(formElem, false);
      throw err;
    }
  });

  /**
   * Submit handler for the callback form.
   *   - format the submit data to a that is better supported by Netlify
   *   - submit the new data and show a modal on success/fail
   *
   * @param {JQuery<HTMLFormElement>} $form The form to submit (JQuery object)
   * @return {Promise} A promise that is resolved after the POST request completes and the success/error modal has been
   *                   shown. It is always returned as resolved, never rejected.
   */
  function submitForm($form) {
    // Get the form data as an array
    let data = $form.serializeArray();
    const inquiryTypes = [];

    // Extract 'inquiry-type' values from the array
    data = data.filter(function(element) {
      if (element.name === 'inquiry-type') {
        if (element.value) inquiryTypes.push(element.value);
        return false;
      }

      return true;
    });

    // Add all inquiry types back as a single value
    data.unshift({
      name: 'inquiry-type',
      value: inquiryTypes.join(', ') || 'Unspecified',
    });

    // Submit the form data via AJAX
    return Promise.resolve($.post($form.attr('action'), data));
  }

  /**
   * Set the 'readOnly' state for each input in the provided <form> element.
   *
   * @param {HTMLFormElement} formElem The <form> element to set the readonly state for.
   * @param {Boolean} readOnly The value of the readonly state to set.
   */
  function setReadOnly(formElem, readOnly) {
    for (const inputElem of formElem.elements) {
      inputElem.readOnly = readOnly;
    }
  }

  /**
   * Callback when the form is submitted successfully.
   */
  function submitSuccess() {
    // Show a success modal
    $('#contact-form-success-modal').modal();

    // Record analytics events
    if (typeof fbq === 'function') fbq('track', 'Lead', {content_name: 'Contact Form'});
    if (typeof gtag === 'function') gtag('event', 'generate_lead');
  }

  /**
   * Callback when the form submission fails.
   */
  function submitFailure() {
    // Show a failure modal
    $('#contact-form-failure-modal').modal();
  }
})();
